<template>
  <view-item title="盘点调价明细">
    <template #operation>
      <button-ajax type="primary" :method="operation.export.click">导出</button-ajax>
    </template>

    <en-card class="h-full" body-class="h-full">
      <flex-box>
        <template #default="{ height }">
          <table-dynamic
            code="IVHSTFD"
            :data="table.data"
            :loading="table.loading"
            :height="height"
            :method="table.get"
            pagination
            :paging="table.paging"
            show-summary
            :summary="table.summary.data"
            :config="table.config"
          ></table-dynamic>
        </template>
      </flex-box>
    </en-card>
  </view-item>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        children: {
          export: {
            ajax: {
              get: {
                action: 'GET /enocloud/inventory/history/export',
                params(params) {
                  params.payload = this.table.$ajaxParams.payload
                }
              }
            },
            async click() {
              await this.operation.export.get()
              this.store.openDownload()
            }
          }
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/inventory/history/query',
            summary: 'GET /enocloud/inventory/history/summary',
            data: 'array',
            loading: true,
            pagination: true
          }
        },
        config: {}
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
